<template>
    <div class="privacypolicy">
        <div class="wrapContentExt">
            <div>
                <img class="logo" alt="lingodeer-website-logo" :src="logo">
            </div>
            <div class="fr-view">
                <h1 style="margin:0;margin-bottom:35px;text-align:center;font-size: 27px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习隐私政策</span>
                </h1>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">欢迎您使用鹿老师说专项练习软件及相关服务！</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">北京易言科技有限公司（以下简称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">公司</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">或</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）非常重视用户信息的保护，在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务前，请您务必仔细阅读《鹿老师说专项练习隐私政策》（以下简称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">），公司可能会收集和使用您的相关信息，您一旦选择使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务，即意味着同意公司按照本隐私政策收集、使用（含商业合作使用）、储存您的相关信息。公司提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为鹿老师说专项练习的用户或无法享受公司提供的某些服务，或者无法达到相关服务拟达到的效果。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们希望通过本隐私政策向您介绍我们对您个人信息的处理方式，因此我们再次建议您务必认真阅读并充分理解本隐私政策，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">18</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">周岁，请您在法定监护人陪同下仔细阅读并充分理解本隐私政策，并征得法定监护人的同意后下载使用本软件。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">如果您不同意本隐私政策的内容，将导致</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务无法正常运行，我们将无法为您提供完整的产品和服务，同时也请您立即停止使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务。当您开始使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务，将视同您完全理解且同意本隐私政策的全部内容。在我们更新本隐私政策后（我们会及时在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">平台发布最新版本），如您继续使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">的软件和服务，即意味着您完全理解本隐私政策（含更新版本）的全部内容，并同意我们按照本隐私政策收集、保存、使用、共享、披露及保护您的相关个人信息。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">如对本隐私政策内容存在任何疑问、意见或建议，您可通过我方平台的客服与我们联系。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为本隐私政策之目的：</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">是指由公司合法拥有并运营的、标注名称为</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">的客户端应用程序以及</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说外语</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;"><a
                        href="http://www.lingodeer.cn/" target="_top"><span style="color:#7D483D;padding:0;">www.lingodeer.cn</span></a></span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）相关网站；</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">用户</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">指所有直接或间接获取和使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务的使用者，包括自然人、法人和其他组织等。在本隐私政策中称为</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">用户</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">或称</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）关联方是指，因共同所有权或控制权而与公司相关的公司、组织或个人；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）第三方是指，并非因为共同所有权或控制权而存在相关关系的公司、组织或个人（即非关联公司）或者其他非相关的个人；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">4</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）个人信息是指，以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括个人身份信息（姓名、出生日期、身份证件号码，包括身份证、军官证、护照、驾驶证）、面部特征、地址、联系电话、通信录、网络身份识别信息（包括账户名、账户昵称、邮箱地址以及与前述有关的密码与密码保护问题和答案）；财产信息、交易信息、个人上网记录（网络浏览记录、点击记录等）、个人常用设备信息（包括硬件型号、设备</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">MAC</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">地址、操作系统类型、软件列表唯一设备识别码，如</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">IMEI/android ID/IDFA/OPENUDID/GUID</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">、</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">SIM</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">卡</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">IMSI</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">卡信息等在内的描述个人常用终端设备基本情况的信息）、个人位置信息等；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">5</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）个人敏感信息是指，一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中的个人敏感信息包括：包括个人身份信息（身份证件号码，包括身份证、军官证、护照、驾驶证）、面部识别特征；财产信息、交易信息；网络身份识别信息（包括帐号名、帐号昵称、邮箱地址以及与前述有关的密码与密码保护问题和答案）；其他信息（包括通信录、个人电话号码、手机号码、行程信息、网页浏览记录、精准定位信息）。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策的适用范围</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策适用于我们向您提供的</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务，无论上述软件及服务是您通过计算机设备、移动设备或其他终端设备获得。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">除本隐私政策另有规定外，本隐私政策所用词语与《鹿老师说专项练习用户服务协议》所定义的词语具有相同的涵义。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策不适用于以下情况：</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1.3.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">通过</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务而接入的第三方服务（包括任何第三方应用及网站）收集的信息；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1.3.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">通过</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务中进行广告服务的其他公司或机构、组织所收集的信息。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1.3.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">公司的服务可能包括或链接至第三方提供的信息或其他服务（包括网站）。该等第三方服务可能由相关的第三方运营。您使用该等第三方服务（包括您向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非本隐私政策）约束，您需要仔细阅读其条款。请您妥善保护自己的个人信息，仅在必要的情况下向他人提供。本隐私政策仅适用于公司所收集、保存、使用的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，公司对任何第三方使用由您提供的信息不承担任何责任。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">个人信息收集的范围与方式</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您向我们提供的信息</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您理解并同意当您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务时，您可能需要填写和</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">/</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">或提供的信息，可能包括姓名、性别、联系方式（如手机号码）等单独或者结合识别用户身份的信息。另外，您可以选择不提供某一或某些信息，但是这样可能使您无法使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">的相关特殊服务。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您以其他方式关联登录、使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务时，我们会向第三方请求您的个人信息（例如您授权共享的第三方社交帐号信息（如头像、昵称等）），并在您同意本隐私政策后将您的第三方社交帐号与您的鹿老师说专项练习帐号绑定，使您可以通过第三方社交帐号直接登录并使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">产品及相关服务。对于我们需要但第三方无法提供的个人信息，我们仍会要求您提供。如果您拒绝提供，将可能导致您无法正常使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务的某些功能。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">因您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务获取的信息</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">日志信息，当您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务时，我们会自动收集您对我们服务的使用情况，作为有关网络日志保存。例如您的搜索查询内容、</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">IP</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">地址、浏览器的类型、电信运营商、使用的语言、访问服务的日期和时间、</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Cookie</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">、</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Web Beacon</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">等。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">设备或应用信息，，某些移动设备或应用包含唯一应用程序编号。例如您使用的移动设备、浏览器或用于接入</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务的其他程序所提供的配置信息、设备版本号、设备识别码等。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为了向您提供更好的服务与改善您的用户体验，</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务可能会记录硬件型号、操作系统版本号、国际移动设备识别码（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">IMEI</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）、网络设备硬件地址（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">MAC</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）、软件列表等软硬件数据。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">位置信息，当您开启设备定位功能并使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务基于位置提供的相关服务时，在获得您的同意后，我们会使用各种信息进行定位，以使得您不需要手动输入自身地理坐标就可获得相关服务。这些信息包括</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">IP</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">地址、</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">GPS</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">信号以及能够提供相关位置信息的其他传感器信息等（如可能需要提供附近设备、</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Wi-Fi</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">接入点和基站的信息）。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为了提升您的体验，我们也可能会通过位置信息用以为您提供更具有个性化的服务，在征得您的同意后，将可能用于向您推荐更符合您个性化需求的内容。您可以通过关闭定位功能，停止向</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务提供您的地理位置信息，我们理解大多数移动设备均允许您（针对特定的产品或服务）关闭定位服务，具体建议请您联系您的移动设备的服务商或生产商。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">联系人信息</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您选择使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">同步通讯录好友</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">功能时，在获得您的明示同意后，公司会收集您的通讯录信息。上述信息属于个人敏感信息，拒绝提供该信息仅会使您无法使用此功能，但不影响您正常使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">"</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">"</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务的其他功能。此外，您也可以随时关闭此功能，并可向公司提出删除通过此功能获悉的您的通讯录信息。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您选择使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">邀请微信好友</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">邀请</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">QQ</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">好友</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">功能时，在获得您同意后，公司会收集您的好友列表、群列表（如有），拒绝提供该信息仅会使您无法使用此功能，但不影响您正常使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">"</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">"</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务的其他功能。此外，您也可以随时关闭此功能，并可向公司提出删除通过此功能获悉的您的好友列表、群列表（如有）信息。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">的搜索服务时，我们会收集您的搜索关键字信息、设备信息等。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地存储设备之中。您理解并同意，如您的搜索关键字信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上的个人信息，因此我们有权以其他的目的对其进行使用；只有当您的搜索关键字信息可以单独或结合其他信息识别到您的个人身份时，则在结合使用期间，我们会将您的搜索关键字信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">在您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务提供的身份认证功能时，我们会根据相关法律法规规定和</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">/</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">或该身份认证功能所必需，收集您的姓名、身份证号等有关身份证明的信息，我们将对您的这些信息会加以最大程度的保护，如果您不提供这些信息，您将可能无法获得相关服务。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您选择参加我们举办的有关营销活动时，我们根据活动需要可能会收集您的姓名、通信地址、联系方式、银行账号等信息。这些信息是您收到转账或者礼品所必要的（其中的银行账号属于个人敏感信息），如果您拒绝提供这些信息，我们将可能无法向您转账或发放礼品。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.8.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您自愿参加我们以问卷、深度访谈、焦点小组等方式开展的市场调研活动时，我们可能会在您同意的前提下匿名收集您的年龄、性别与联系方式等信息，以完成相应的用户体验调研。除非得到您的事先同意，这些信息将仅用于前述之目的，我们将不会向任何第三方披露您的任何相关信息，或将该等信息用于其他目的。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.9.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为提高您使用我们及我们关联公司、合作公司提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">相关协议规则的情况，我们可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.10.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您与我们联系时，我们可能会保存您的通信</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">/</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2.2.11.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">其他信息。例如，当您使用我们的增值服务以提升用户体验时，我们可能会留存您的充值记录，以确保相关交易的可追溯性和安全性；但是，我们不会因此而收集或存储您的银行卡信息或身份认证信息。例如，您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。向</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">提供该其他方的前述个人信息之前，您需确保您已经取得其授权同意。例如，您在使用我们的内容发布服务时明确同意开启设备相册功能后向我们提供的本地相册信息。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">个人信息可能的使用方式</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为实现</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务的功能，我们会根据本隐私政策的约定对所收集的您的个人信息进行使用。如有除此之外的任何其他用途，我们都会提前征得您的同意。具体而言，我们将为以下目的使用所收集的您的个人信息：</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）通过</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务向您提供尽可能个性化、高质量的服务；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）向您提供更加相关的广告，以替代传统普遍投放的广告；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）在我们提供相应服务所必需时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，努力确保我们向您提供的产品和服务的安全性；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">4</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）通过数据分析，更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示等。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为了努力确保服务的安全，帮助我们更好地了解</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务的运行情况，我们可能记录您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务的信息数据，例如，您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务的频率、崩溃数据、总体使用情况、性能数据等。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为了让您有更好的体验、改善我们的服务或您同意的个人信息使用目的，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息数据，以汇集信息数据或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，以降低其他组织或个人通过去标识化处理后的个人信息识别到您的风险，并采取技术和管理方面的措施，将去标识化后的数据与可用于恢复识别个人的信息分开存储。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">请您注意，除非您删除或撤回同意（即通过系统设置拒绝我们的收集和使用），您在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务时所提供的所有个人信息，将在您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务期间持续授权我们在符合本隐私政策的范围内使用。在您注销账号后，我们将停止为您提供</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务，根据您的要求删除您的个人信息，或做匿名化处理，但法律法规另有规定的除外。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">3.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们会对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的软件和服务的整体使用趋势。但这些统计信息不会包含您的任何个人信息。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">个人信息的存储</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">4.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">信息存储的地点</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">4.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">存储期限</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们仅在为提供</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及服务之目的所必需的期间内保留您的个人信息，但法律法规另有规定的除外。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">对</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Cookie</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">和同类技术的使用</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">5.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您理解并同意，当您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务时，为使您获得更轻松的访问体验，我们可能会使用各种技术来收集和存储信息，在此过程中可能会向您的设备发送一个或多个</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Cookie</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">或匿名标识符。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">5.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务时，我们可能会利用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Cookie</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">和同类技术收取您的信息用于了解您的偏好和使用习惯，进行咨询或数据分析，改善产品服务及用户体验，提高广告效果，及时发现并防范安全风险，为您提供更好的服务。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">5.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们不会将</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Cookie</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">用于本《隐私政策》所述目的之外的任何其他用途，您可以根据自己的偏好留存或删除</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Cookie</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">，也可以清除软件或网页中保存的所有</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">Cookie</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">个人信息可能共享的方式</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们会以高度的勤勉义务对待您的信息。在获得您的明确同意后，我们可能会与第三方共享您的个人信息。另外，我们可能会按照法律法规的要求或强制性的政府要求或司法裁定等，与相关的第三方共享您的个人信息。通过使用收集的信息，向您提供更加相关广告以替代普遍投放的广告。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">在获得您的同意后，我们可能会将您的个人信息与我们的关联方共享。受限于您在本隐私政策中所同意的个人信息使用方式与目的，我们只会与关联方共享必要的个人信息。关联方如因业务需要，确需超出前述授权范围使用个人信息的，将再次征求您的授权同意。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为实现</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">产品和服务的完整功能以及本隐私政策中声明的若干目的，我们的某些服务将可能由授权第三方提供。我们可能会与此等授权第三方共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供该等服务所必要的个人信息。授权第三方无权将共享的个人信息用于任何其他用途，或向其他任何第三方作进一步的披露。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您同意我们与如下授权第三方共享信息：</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">1</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）软件服务提供商、智能设备提供商或系统服务提供商。当第三方软件、设备、系统与</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">产品和服务结合为您提供基于位置的服务时，我们可能会基于您对系统定位的授权及设定，收集您的位置信息及相关设备信息（例如硬件型号、操作系统版本号、国际移动设备身份识别码（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">IMEI</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）、网络设备硬件地址（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">MAC</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">））并提供给前述提供商，特殊情境下会包含您另行填写的其他信息。如您拒绝此类信息的收集及使用，您可以在设备系统中进行设置或关闭提供服务的软件。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">（</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">2</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">）广告服务提供商。未经您授权，我们不会将您的个人信息与广告服务提供商进行共享。但我们可能会将难以识别您个人身份的用户画像标签与广告服务商共享，以帮助其在不识别您个人身份的前提下提升广告有效触达率。例如，只有在广告主同意遵守我们的广告发布规范后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些合作伙伴提供不能识别个人身份的统计信息（例如</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">男性，</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">22-25</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">岁，位于北京</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">），帮助他们了解其受众或顾客。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">对我们与之共享您个人信息的第三方，我们会对其数据安全能力与环境进行调查，与其签署严格的保密协定，并要求他们按照您同意的方式和目的、依据本隐私政策以及其他任何相关的保密和安全措施来处理您的个人信息。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。在发生前述变更时，我们将在转移您的个人信息前通知您有关情况，并按照法律法规及不低于本隐私政策所要求的安全标准继续保护或要求新的个人信息控制者继续保护您的个人信息。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">未经您的同意，公司不会向公司以外的任何公司、组织和个人提供、披露您的个人信息，但下列情况除外：</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.7.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">事先获得您的明确授权；</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.7.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您自行向第三方共享的；</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.7.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.7.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">根据适用的法律法规的要求、强制性的行政和司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的。在符合法律法规的前提下，当我们收到前述披露信息的请求时，我们会要求对方必须出具与之相应的法律文件，如传票或调查函。我们将对所有的请求都进行审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；公司为维护合法权益而向用户提起诉讼或仲裁；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.7.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">在法律法规允许的范围内，为维护</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">其他用户、公司及其关联方的生命、财产等合法权益或维权产品或服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等（不包括违反本隐私政策中所做的承诺而为获利目的对外公开或提供个人信息）；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.7.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">6.7.7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">法律法规规定的其他情形。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">个人信息安全保护</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">7.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们非常重视您个人信息的安全，将努力采取各种符合业界标准的合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息，防止数据被不当使用或被未经授权的访问、公开披露、使用、修改、损坏、丢失或泄漏。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">7.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们会使用加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用受信赖的保护机制防止您的个人信息遭到恶意攻击。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">7.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">7.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入公司的服务所用的系统和通讯网络，有可能因公司可控范围外的因素而出现问题。因此，您应采取积极措施保护个人信息的安全，如：定期修改密码，不将自己的帐号密码等个人信息透露给他人。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">7.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止该等安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">7.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们谨此特别提醒您，本隐私政策提供的个人信息保护措施仅适用于</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务，一旦您离开</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件和服务，浏览或使用其他网站、服务及内容资源，</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">即没有能力及义务保护你在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">之外的网站提交的任何个人信息，无论你登录或浏览上述网站是否基于</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">的链接或引导。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">个人信息的管理</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的访问、修改（更新或更正）、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">当您完成</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">的帐号注册、登录并进行合理和必要的身份验证后，您可以查阅、修改、删除您提交给</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务的个人信息。您理解并同意，一般情况下，您可以通过前往</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我的</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">页面随时浏览、修改、删除自己提交的信息，但出于安全性和身份识别的考虑，您可能无法自主修改注册时提交的某些初始注册信息；如您确有必要修改该类信息，请您通客服联系我们，公司将尽快审核所涉问题，并由专人验证您的用户身份后及时予以回复。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您可以通过删除信息、关闭设备功能、在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">客户端应用程序中通过</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我的</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">—</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">编辑资料</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">，改变您在等功能和服务中授权公司继续收集个人信息的范围或撤回您的授权。请您理解，特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，公司无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响公司此前基于您的授权而开展的个人信息处理。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">如您需要注销您的</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">帐号，请通过我方平台的客服进行操作。在您注销帐号前，我们将验证您的个人身份、安全状态、设备信息等。您知悉并理解，注销帐号的行为是不可逆的行为，一旦您注销帐号，我们将停止为您提供相关服务，并依照您的要求删除有关您帐号的一切信息或对相关信息进行匿名化处理，但法律法规另有规定的除外。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务的某些业务功能中，公司可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">对于您上述合理的请求，我们原则上不会收取任何费用。但对多次重复、超出合理限度的请求，我们将视实际情况收取一定成本费用。对于无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您的信息有以下情形之一时，按照法律法规要求，我们可能无法响应您的请求：</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.7.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">与国家安全、国防安全有关的；</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.7.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">与公共安全、公共卫生、重大公共利益等有关的；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.7.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">与犯罪侦查、起诉、审判和执行判决等有关的；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.7.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">有充分证据表明您存在主观恶意或滥用权利的；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.7.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.7.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">涉及商业秘密的；</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.7.7.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">其他法律法规规定的情形。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">8.8.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">9.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">未成年人使用条款</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">9.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">若您是未满</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">18</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">周岁的未成年人，在使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务前，应在您的父母或其他监护人监护、指导下阅读并同意本隐私政策并获得监护人同意情况下阅读本隐私政策和使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务。</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">9.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并在监护人指导时正确使用</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务。公司只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用或公开披露未成年人的个人信息；如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，将会设法尽快删除相关数据。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">隐私政策的变更</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">为了给您提供更好的服务，</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">软件及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，该等修订构成本隐私政策的一部分并具有等同于本隐私政策的效力。但未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策更新后，我们会在</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">“</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">鹿老师说专项练习</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">”</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">应用程序客户端和官方网站发出更新版本，并在更新后的条款生效前以适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信、私信或在浏览页面做特别提示等方式，说明具体的变更内容）。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策所指的重大变更包括但不限于：</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.4.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.4.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有变更等；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.4.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">个人信息共享、转让或公开披露的主要对象发生变化；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.4.4.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">您参与个人信息处理方面的权利及其行使方式发生重大变化；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.4.5.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">10.4.6.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">个人信息安全影响评估报告表明存在高风险时。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">11.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">其他</span></p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">11.1.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。倘本隐私政策之任何规定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将尽可能接近本隐私政策原条文意旨重新解析，且本隐私政策其它规定仍应具有完整的效力及效果。若您与公司发生争议的，双方应尽量友好协商解决，协商不成，您同意应将争议提交至北京市海淀区人民法院诉讼解决。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">11.2.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策中的标题仅为方便及阅读而设，并不影响本隐私政策中任何规定的含义或解释。</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;vertical-align:baseline;">
                    <span style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">11.3.</span><span
                        style="font-size:18px;font-family:宋体;color:#0A0A0A;padding:0;">本隐私政策的版权为公司所有，在法律允许的范围内，公司保留解释和修改的权利。</span><span
                        style="font-size:18px;font-family:Helvetica,sans-serif;color:#0A0A0A;padding:0;">&nbsp;</span>
                </p>
                <p style="margin:0;margin-bottom:10px;text-align:left;font-size:16px;font-family:Cambria,serif;">
                    <br></p>
                <p style="margin:0;margin-bottom:10px;text-align:justify;font-size:16px;font-family:Cambria,serif;">
                    <br></p>
            </div>
        </div>
    </div>
</template>

<script>
    import Constants from "../../utils/Constants";

    export default {
        data() {
            return {
                logo: Constants.landPagePic.logo
            }
        }
    }
</script>

<style lang="scss" scoped>
    .privacypolicy {
        a {
            word-break: break-word;
        }

        .logo {
            height: 21px;
            margin: auto;
            display: block;
            padding-top: 35px;
            padding-bottom: 70px;
        }

        .wrapContentExt {
            background-color: #ffffff;
            width: 590px;
            padding: 13px 70px;
            margin: 30px auto;
            box-shadow: 0px 0px 10px 3px #d3b3b3;
            margin-bottom: 100px;
            padding-bottom: 60px;

            &.phone {
                background-color: #ffffff;
                padding: 0 18px;
                padding-bottom: 60px;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .privacypolicy {
            .logo {
            }

            .wrapContentExt {
                width: auto !important;
                box-shadow: none !important;
                background-color: #ffffff !important;
                padding: 0 18px !important;
                padding-bottom: 60px !important;
                margin: 0 !important;
            }
        }
    }
</style>